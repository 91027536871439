import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import IconsBar from '../components/shared/icons-bar/IconsBar';
import styles from './tile-details-template.module.scss';
import { graphql } from 'gatsby';
import FabricSliderWithThumbnail from '../components/shared/slider-with-thumbnail/fabric/FabricSliderWithThumbnail';
import ImageSliderWithThumbnail from '../components/shared/slider-with-thumbnail/image/ImageSliderWithThumbnail';
import { TileDetails } from './tile-details.interface';
import { FinishInfo } from '../components/shared/interfaces';
import { SlugType } from './slug-type.interface';
import useViewport from '../hooks/useViewport';
import DetailsLIst from './details-list';
import SlabInventoryPopUp from '../components/custom/slab-inventory-popup/SlabInventoryPopUp';
import { FavoriteTypes } from '../components/shared/enum/favorite-data-type';
import useFavourite from '../hooks/useFavourite';

interface ImagesArray {
  image: {
    url: string;
    caption?: string;
  };
}

const TileDetailsPage: React.FC<Props> = (props: Props) => {
  formatData(props);
  const { isMobile } = useViewport();
  const tile = props.data.strapi.tile;
  const [finishId, setFinishId] = useState(0);

  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);

  const setSavedFavorites = (isFavoriteSaved: boolean) => {
    setIsFavoriteSaved(isFavoriteSaved);
  };

  const tileImagesArray = [];
  const image: ImagesArray = { image: { url: '' } };

  for (const key of tile.images) {
    image['image'] = key;
    tileImagesArray.push({ ...image });
  }

  return (
    <Layout isWhiteBackground={true}>
      <SEO title={tile.name} />
      <section className={styles.wrapper}>
        <div className={styles.detailsBox}>
          <div className={styles.sliderBox}>
            <nav className={styles.iconsBar}>
              <IconsBar
                imageUrl={tile.finishes[finishId]?.image?.url}
                descrioptionPdfUrl={tile.tear_sheet?.url}
                techDataPdfUrl={tile.tech_data?.url}
                isFavoriteSaved={isFavoriteSaved}
              >
                <SlabInventoryPopUp
                  favouriteAPI={useFavourite()}
                  title={tile.name}
                  materialName={tile.material?.name}
                  finishName={tile.finishes[finishId]?.name}
                  imagesPreview={tile.finishes[finishId]?.image?.url}
                  collectionId={tile.id}
                  collectionType={FavoriteTypes.TILE}
                  setSavedFavorites={setSavedFavorites}
                />
              </IconsBar>
            </nav>
            <section className={styles.slider}>
              <h1 className={styles.title}>
                {tile.tile_collection.name.toUpperCase()} | {tile.name.toUpperCase()}
              </h1>
              <div className={styles.orderOne}>
                <FabricSliderWithThumbnail
                  tile={tile}
                  setFinishId={setFinishId}
                  chosenFinishId={finishId}
                />
              </div>
              <div className={styles.orderThree}>
                {!!tile.images?.length && (
                  <div className={styles.imageSliderHeadline}>
                    <div></div> IMAGES <div></div>
                  </div>
                )}
                {!!tile.images?.length && (
                  <ImageSliderWithThumbnail slideHeight={'500px'} images={tileImagesArray} />
                )}
              </div>
              {isMobile && (
                <div className={styles.orderTwo}>
                  <DetailsLIst
                    tile={tile}
                    pageContext={props.pageContext}
                    setFinishId={setFinishId}
                    chosenFinishId={finishId}
                  />
                </div>
              )}
            </section>
          </div>
          <div className={styles.descriptionBox}>
            <section className={styles.detailsDivider}>
              <div className={styles.delimiter}></div>
              <div className={styles.content}>
                <span className={styles.dividerHeader}>
                  {tile.tile_collection.name.toUpperCase()}
                </span>
                <span className={styles.dividerText}>SCROLL TO SEE MORE</span>
              </div>
            </section>
            {!isMobile && (
              <DetailsLIst
                tile={tile}
                pageContext={props.pageContext}
                setFinishId={setFinishId}
                chosenFinishId={finishId}
              />
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

interface Props {
  data: {
    strapi: {
      tile: TileDetails;
      finishes: FinishInfo[];
    };
  };
  pageContext: {
    id: number;
    slug: SlugType;
  };
}

export const query = graphql`
  query($id: ID!) {
    strapi {
      tile(id: $id) {
        leadTime: LeadTime
        range: Range
        id
        name
        tile_collection {
          name
        }
        description
        material {
          name
          id
        }
        color {
          id
          name
        }
        tear_sheet {
          url
        }
        tech_data {
          url
        }
        color_secondary {
          name
          id
        }
        color_tertiary {
          name
          id
        }
        preview {
          url
        }
        images {
          url
          caption
        }
        special_order_finishes
        inStock
        finishes: tile_finishes(sort: "sortIndex:asc") {
          finish {
            id
          }
          sizes
          image {
            url
          }
          out_of_stock
        }
        standard_finishes {
          name
        }
        applications
      }
      finishes {
        name
        id
        is_textured
      }
    }
  }
`;

function formatData(props: Props): void {
  props.data.strapi.tile.descrioptionPdfUrl = props.data.strapi.tile?.tear_sheet?.url;
  props.data.strapi.tile.techDataPdfUrl = props.data.strapi.tile?.tech_data?.url;
  for (const finish of props.data.strapi.tile.finishes) {
    if (finish.finish) {
      const info = props.data.strapi.finishes.find((f) => f.id === finish.finish?.id);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      finish.name = info.name;
    }
  }
}

export default TileDetailsPage;
