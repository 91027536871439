import React, { useState, useEffect } from 'react';
import styles from './FabricSliderWithThumbnail.module.scss';
import arrowRight from '../../../../images/white-arrow-right.svg';
import arrowLeft from '../../../../images/white-arrow-left.svg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TileDetails } from '../../../../templates/tile-details.interface';

interface Props {
  tile: TileDetails;
  chosenFinishId: number;
  setFinishId: (id: number) => void;
}

/*
 * fill all available width
 * height auto according to images aspect ratio
 * */
const FabricSliderWithThumbnail: React.FC<Props> = ({
  tile,
  setFinishId,
  chosenFinishId,
}: Props) => {
  // should be the same on scss
  // :global {
  //   .fabric_slider {
  const sliderName = 'fabric_slider';
  const thumbIdPrefix = Math.random().toString();

  const [thumbTotalWidth, setThumbTotalWidth] = useState(0);
  const [isWidePhoto, setIsWidePhoto] = useState<boolean | null>(null);

  const findThumbTotalWidthFunc = () => {
    if (typeof document !== 'undefined') {
      const thumbsSlider: any = document.querySelector(`.${sliderName} .thumbs.animated`)!;

      let totalX = 0;
      if (thumbsSlider !== null) {
        for (let j = 0; j < tile.finishes.length; j++) {
          totalX += thumbsSlider.childNodes[j]?.getBoundingClientRect().width;
        }
      }
      setThumbTotalWidth(totalX);
    }
  };

  const findFirstImg = () => {
    if (
      typeof document !== 'undefined' &&
      document.querySelector(`.${sliderName} .thumbs.animated`) !== null
    ) {
      const firstImg: any = document.querySelector(`.${sliderName} .thumbs.animated`)!;

      const firstImgData = firstImg.childNodes[0].getBoundingClientRect();

      firstImgData.width > firstImgData.height ? setIsWidePhoto(true) : setIsWidePhoto(false);
    }
  };

  setTimeout(() => {
    findFirstImg();
  }, 1000);

  const getThumbId = (i: number): string => `${thumbIdPrefix}_${i}`;

  const [translatedX, setTranslatedX] = useState(0);
  const [thumbWidth, setthumbWidth] = useState(0);
  const [thumbsContainer, setthumbsContainer] = useState<Element>();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setthumbsContainer(document.querySelector(`.${sliderName} .thumbs-wrapper.axis-vertical`)!);

      findThumbTotalWidthFunc();
      setTimeout(() => {
        findThumbTotalWidthFunc();
        findFirstImg();
      }, 5000);
      findFirstImg();
    }
  }, []);

  const thumbSliderWidth = thumbsContainer?.getBoundingClientRect().width;

  const increase = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    thumbsContainer.style.transform = `translate3d(-${thumbWidth + thumbSliderWidth}px, 0px, 0px)`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setthumbWidth(thumbWidth + thumbSliderWidth);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTranslatedX(thumbWidth + thumbSliderWidth);
  };

  const decrease = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    thumbsContainer.style!.transform = `translate3d(-${
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      thumbWidth < thumbSliderWidth ? 0 : thumbWidth - thumbSliderWidth
    }px, 0px, 0px)`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setthumbWidth(thumbWidth < thumbSliderWidth ? 0 : thumbWidth - thumbSliderWidth);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTranslatedX(thumbWidth < thumbSliderWidth ? 0 : thumbWidth - thumbSliderWidth);
    if (thumbWidth <= 0) {
      setthumbWidth(0);
    }
  };

  return (
    <div className={styles.FabricSliderWithThumbnail + ' ' + sliderName}>
      <Carousel
        selectedItem={chosenFinishId}
        swipeable={true}
        showStatus={false}
        // CUSTOM THUMBS ANIMATION
        // unfortunately 'react-responsive-carousel' cant calc slide width -
        // because it's dynamically changed according to the thumb image
        // we have to customize thumbs animation
        onChange={(i) => {
          setFinishId(i);

          // eslint-disable-next-line
          const thumbNode = document.getElementById(getThumbId(i))!;

          if (!thumbNode) {
            return;
          }
          const thumbRect = thumbNode.getBoundingClientRect();

          // eslint-disable-next-line
          const thumbsWrapper: any = document.querySelector(
            `.${sliderName} .thumbs-wrapper.axis-vertical`,
          )!;

          // eslint-disable-next-line
          const thumbsSlider: any = document.querySelector(`.${sliderName} .thumbs.animated`)!;
          const thumbSliderWidth = thumbsSlider.getBoundingClientRect().width;

          let totalX = 0;
          for (let j = 0; j < i; j++) {
            totalX += thumbsSlider.childNodes[j].getBoundingClientRect().width;
          }

          if (totalX + thumbRect.width > thumbSliderWidth + translatedX) {
            //translatedX = totalX;
            thumbsWrapper.style.transform = `translate3d(-${totalX}px, 0px, 0px)`;
            setTranslatedX(totalX);
            setthumbWidth(totalX);
          } else if (totalX < translatedX) {
            //translatedX = totalX;
            thumbsWrapper.style.transform = `translate3d(-${totalX}px, 0px, 0px)`;
            setTranslatedX(totalX);
            setthumbWidth(totalX);
          }
        }}
        renderArrowPrev={(onClickHandler, hasPrev, label) => (
          <>
            {hasPrev && (
              <button
                className={styles.navBtn + ' ' + styles.prev}
                type="button"
                onClick={onClickHandler}
                title={label}
              >
                <img src={arrowLeft} alt="arrow left" />
              </button>
            )}
            {thumbWidth !== 0 && (
              <button className={styles.navBtnP} type="button" onClick={decrease} title={label}>
                <img
                  src={arrowLeft}
                  alt="arrow left"
                  style={{ height: '1.6rem', marginTop: '0.5rem' }}
                />
              </button>
            )}
          </>
        )}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <>
              <button
                className={styles.navBtn + ' ' + styles.next}
                type="button"
                onClick={onClickHandler}
                title={label}
              >
                <img src={arrowRight} alt="arrow left" />
              </button>

              {thumbWidth + thumbSliderWidth! < thumbTotalWidth && (
                <button
                  className={styles.navBtnN}
                  type="button"
                  onClick={() => {
                    increase(), findThumbTotalWidthFunc();
                  }}
                  title={label}
                >
                  <img
                    src={arrowRight}
                    alt="arrow left"
                    style={{ height: '1.6rem', marginTop: '0.5rem' }}
                  />
                </button>
              )}
            </>
          )
        }
        renderIndicator={() => <></>}
        // eslint-disable-next-line
        renderThumbs={(children: any[]) =>
          // eslint-disable-next-line
          children.map((item: any, i) => {
            let src: string;
            if (Array.isArray(item.props.children)) {
              src = item.props.children[0].props.children.props.src;
            } else {
              src = item.props.children.props.src;
            }

            return (
              <div key={i} id={getThumbId(i)} className={styles.thumbItem}>
                <div>
                  <div className={styles.thumbImageWrap}>
                    <img key={i} src={src} alt="thumbnail img" />
                  </div>
                  {/* <p>FINISH</p> */}
                  {/* <p>{tile.inStock ? 'STOCKED' : 'SPECIAL ORDER'}</p> */}
                  <p>{!tile.finishes[i].out_of_stock ? 'STOCKED' : 'SPECIAL ORDER'}</p>
                  <p>{tile.finishes[i].name}</p>
                </div>
              </div>
            );
          })
        }
      >
        {tile.finishes.map((f, i) => (
          <div key={i} className={styles.slideItem}>
            <div className={styles.img}>
              <img src={f.image?.url} alt="slide image" />
            </div>
            <div className={styles.slideName}>
              <p>{tile.name}</p>
              <p>{`${tile.material?.name?.toUpperCase()} | ${f.name?.toUpperCase()}`}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default FabricSliderWithThumbnail;
