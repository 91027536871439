import React, { FC } from 'react';
import styles from './tile-details-template.module.scss';
import DetailsDataCell from '../components/slab-product/details-data-cell/DetailsDataCell';
import DropdownDataCell from '../components/slab-product/dropdown-data-cell/DropdownDataCell';
import { TileDetails } from './tile-details.interface';
import { SlugType } from './slug-type.interface';
import useViewport from '../hooks/useViewport';

interface Props {
  tile: TileDetails;
  pageContext: {
    id: number;
    slug: SlugType;
  };
  chosenFinishId: number;
  setFinishId: (id: number) => void;
}

const DetailsLIst: FC<Props> = ({ tile, pageContext, setFinishId, chosenFinishId }: Props) => {
  const isOutdoor = pageContext.slug === SlugType.OUTDOOR;
  const isFieldTile = pageContext.slug === SlugType.FIELD;
  const finishes = tile.finishes.sort((a, b) =>
    b.out_of_stock === a.out_of_stock ? 0 : b.out_of_stock ? -1 : 1,
  );
  const { isMobile } = useViewport();

  return (
    <section className={styles.details}>
      <span
        className={styles.detailsHeader}
        style={{
          order: 0,
          width: isMobile ? '90vw' : undefined,
        }}
      >
        <div style={{ marginBottom: '.5rem', display: 'flex', flexWrap: 'wrap' }}>
          <h1 style={{ display: 'inline', lineHeight: 1 }}>{tile.name}</h1>
          <span style={{ margin: '0 5px' }}>|</span>
          <h2 style={{ display: 'inline', whiteSpace: 'break-spaces' }}>
            {finishes[chosenFinishId]?.name}
          </h2>
        </div>
        <h2>{!finishes[chosenFinishId]?.out_of_stock ? 'STOCKED' : 'SPECIAL ORDER'}</h2>
      </span>

      <div
        style={{
          display: 'flex',
          width: '27rem',
          justifyContent: 'space-between',
          order: 2,
        }}
      >
        <div style={{ width: '45%' }}>
          <DetailsDataCell title="MATERIAL" value={tile.material?.name} width="100%" />
        </div>

        <div style={{ width: '45%' }}>
          <DetailsDataCell
            title="COLOR"
            width="100%"
            value={[tile.color, tile.color_secondary, tile.color_tertiary]
              .filter((c) => c)
              .map((c) => c.name)
              .join(', ')}
          />
        </div>
      </div>

      <DetailsDataCell title="RANGE" value={tile.range ?? ' '} order={12} />

      {isOutdoor && (
        <DetailsDataCell title="SPECIAL ORDER LEAD TIME" value={tile.leadTime ?? ' '} order={10} />
      )}
      <DropdownDataCell
        isOutdoor={isOutdoor}
        title={`AVAILABLE FINISHES`}
        chosenFinishId={chosenFinishId}
        finishes={finishes}
        setFinishId={setFinishId}
        order={4}
      />
      {tile.special_order_finishes && (
        <DetailsDataCell
          title="SPECIAL ORDER FINISHES"
          value={tile.special_order_finishes}
          order={8}
        />
      )}

      {isOutdoor ? (
        <>
          <DetailsDataCell
            title="STANDARD FINISHES"
            value={tile.standard_finishes?.map((p) => p.name).join('\n') ?? ''}
            // notes={'Contact us if the finish you need is not in stock'}
            order={6}
          />
          {tile.applications && (
            <DetailsDataCell title="APPLICATIONS" value={tile.applications} order={14} />
          )}
        </>
      ) : (
        finishes[chosenFinishId] !== undefined &&
        finishes[chosenFinishId].name !== undefined && (
          <DetailsDataCell
            title="SIZES AVAILABLE"
            titleLeftSpan={finishes[chosenFinishId]?.name.toUpperCase() + ' '}
            value={finishes[chosenFinishId]?.sizes}
          />
        )
      )}

      {tile.description && (
        <DetailsDataCell
          title="SPECIAL CHARACTERISTICS"
          value={tile.description}
          isText={true}
          order={16}
        />
      )}
      {isFieldTile && (
        <DetailsDataCell
          title="FOR STOCKED FINISHES"
          value="Many tiles are stocked locally in San Carlos, but some are stocked remotely. Please call us for details"
          order={20}
        />
      )}
    </section>
  );
};

export default DetailsLIst;
