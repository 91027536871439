import React, { useState } from 'react';
import styles from './DropdownDataCell.module.scss';
import { FinishInfo, TileFinishInfo } from '../../shared/interfaces';
import arrow from '../../../images/black-arrow-right.svg';
import cn from 'classnames';

interface Props {
  title: string;
  chosenFinishId: number;
  finishes: Array<FinishInfo | TileFinishInfo>;
  setFinishId: (id: number) => void;
  isOutdoor: boolean;
  order?: number;
}

const DropdownDataCell: React.FC<Props> = ({
  title,
  finishes,
  chosenFinishId,
  setFinishId,
  isOutdoor,
  order,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const finishesInStock = finishes;

  const chosenFinish = finishes[chosenFinishId];

  const otherFinishes = finishes.filter((f, ii) => ii !== chosenFinishId);

  return (
    <div className={styles.dropdownWrap} style={{ order: order }}>
      <div
        className={cn(
          styles.sortFilter,
          { [styles.open]: isOpened },
          { [styles.sortFilterDoor]: isOutdoor },
        )}
      >
        <div className={styles.select}>
          <div
            className={cn(styles.item, { [styles.paddingLeft]: isOpened })}
            onClick={() => finishesInStock.length > 1 && setIsOpened(!isOpened)}
          >
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: isOutdoor
                  ? 'FINISHES AND SIZES SHOWN'
                  : `${title} <span>(${finishesInStock.length})</span>`,
              }}
            ></div>
            <span className={styles.finishName}>
              {' '}
              {finishesInStock.length ? chosenFinish?.name : 'Special Order Only'}
            </span>
            {isOutdoor ? (
              // <p className={styles.finishSizes}>{chosenFinish?.sizes?.replace(/[,]/g, ' |')}</p>
              <p className={styles.finishSizes}>
                {!chosenFinish.out_of_stock
                  ? chosenFinish?.sizes?.replace(/[,]/g, ' |')
                  : 'Special Order Only'}
              </p>
            ) : (
              ''
            )}

            {finishesInStock.length > 1 && <img src={arrow} alt="black arrow right" />}
            <div className={styles.divider} style={!isOpened ? { display: 'none' } : {}}></div>
          </div>

          {otherFinishes.map((f, i, arr) => {
            const fIndex = finishes.indexOf(f);
            return (
              <div
                className={cn(styles.item, { [styles.paddingLeft]: isOpened })}
                onClick={() => {
                  setIsOpened(!isOpened);
                  setFinishId(fIndex);
                }}
                key={i}
              >
                <span className={styles.finishName}>{f.name}</span>
                {isOutdoor ? (
                  <p className={styles.finishSizes}>
                    {!f.out_of_stock ? f.sizes?.replace(/[,]/g, ' |') : 'Special Order Only'}
                  </p>
                ) : (
                  ''
                )}
                {i === arr.length - 1 ? <img src={arrow} alt="black arrow right" /> : ''}
                {i !== otherFinishes.length - 1 && (
                  <div
                    className={styles.divider}
                    style={!isOpened ? { display: 'none' } : {}}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.delimiter}></div>
    </div>
  );
};

export default DropdownDataCell;
